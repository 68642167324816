import React from "react";
import "./App.css";
import "./index.css";
import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import Companies from "./components/Companies";
import Features from "./components/Features";
import Partners from "./components/Partners";
import Footer from "./components/Footer";

export default function App() {
  return (
    <div className={"text-white overflow-hidden"}>
      <div className={"bg-highlight relative"}>
        <div className={"absolute bottom-0 right-0  -ml-[300px] lg:ml-0 opacity-40 lg:opacity-100"}>
          <img src="/hero.png" alt="" />
        </div>
        <div className="container mx-auto relative  px-4 md:px-8">
          {/* Navigation */}
          <Navigation></Navigation>
          {/* Hero */}
          <Hero></Hero>
        </div>
      </div>
      <div className={"bg-background-blue "}>
        <div className={"container mx-auto px-4 md:px-8 flex flex-col space-y-[120px]"}>
          {/* Projects */}
          <Projects></Projects>
          {/* Companies */}
          <Companies></Companies>
          {/* Features */}
          <Features></Features>
        </div>
      </div>
      <div className={"bg-highlight"}>
        <div className={"container mx-auto  px-4 md:px-8"}>
          {/* Partners */}
          <Partners></Partners>
        </div>
      </div>

      <div className={"bg-background-blue"}>
        <div className="container mx-auto px-4 md:px-8">
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
