interface ButtonData {
  text: string;
  target?: string;
  url?: string;
  extra?: string;
  extra2?: string;
}

export default function Button({
  text,
  target = "_self",
  url = "mailto:office@gt3.agency",
  extra,
    extra2
}: ButtonData) {
  return (
    <div className={extra}>
      <a
        href={url}
        target={target}
        type="button"
        className={`${extra} w-[185px] h-[53px] focus:outline-none rounded-full text-sm text-center mx-auto items-center justify-center flex  bg-gradient uppercase cursor-pointer`}
      >
          <div className={`bg-highlight hover:bg-gradient w-[179px] h-[49px] rounded-full items-center justify-center flex ${extra2}`}>
              {text}
          </div>

      </a>
    </div>
  );
}
