import Button from "./Button";

export default function Projects() {
  const data = [
    {
      title: "Stellar Polygon Bridge",
      description:
        "We successfully developed the world’s first direct Stellar-Polygon token bridge in 2021, which was supported by a Polygon Development Grant. The bridge also integrates Chainlink’s state-of-the-art Proof of Reserves, bringing it an unparalleled level of security.",
      color: "text-pink",
    },
    {
      title: "AI Development",
      description:
        "We developed Saige, the industry-leading AI-powered proprietary trading indicator. xBased on sentiment data and constantly self-improving machine learning, the indicator predicts closing prices for top crypto assets.",
      color: "text-purple",
    },
    {
      title: "Copy Trading",
      description:
        "The Mercury Trade copy trading platform brings user-friendliness and security together in one groundbreaking package. Custodian risk is eliminated by using API trade executions with users’ existing exchange accounts, while cross-exchange copy trading (set to be launched soon) will offer unprecedented liquidity and convenience.",
      color: "text-blue",
    },
  ];

  return (
    <div id={"development"}>
      <div
        className={
          "flex flex-wrap gap-16 pt-[90px] md:pt-[120px] justify-around items-stretch"
        }
      >
        {data.map((child) => (
          <div
            key={child.title}
            className={
              "max-w-[334px] w-full bg-white-gradient p-[2px] rounded-xl hover:-translate-y-6 ease-in-out duration-500"
            }
          >
            <div
              className={
                "bg-dark-blue-gradient  rounded-xl py-[42px] px-[30px] h-full"
              }
            >
              <h2 className={`text-h3 ${child.color}`}>{child.title}</h2>
              <p className={"text-m2 mt-6 h-full text-gray-30"}>
                {child.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className={"flex justify-center py-10"}>
        <Button text={"contact us"} />
      </div>
    </div>
  );
}
