export default function Features() {
  const data = [
    {
      title: "Security",
      description:
        "We successfully developed the world’s first direct Stellar-Polygon token bridge in 2021, which was supported by a Polygon Development Grant. The bridge also integrates Chainlink’s state-of-the-art Proof of Reserves, bringing it an unparalleled level of security.",
      icon: "/features/shield.svg",
    },
    {
      title: "Reliability",
      description:
        "We care about sticking to your roadmap just as much as you do, and our development is always aligned with your broader objectives. We deliver either on time or ahead of time, always.",
      icon: "/features/clock.svg",
    },
    {
      title: "Results",
      description:
        "We have a dedicated team of analysts in both marketing and IT solutions, making sure that our products will meet the KPIs that we set out.",
      icon: "/features/diamond.svg",
    },
    {
      title: "Trustworthiness",
      description:
        "Our products are trusted by hundreds of thousands of users, and we’ve been developing cutting-edge tech since 2018 without a single security incident.",
      icon: "/features/shake.svg",
    },
  ];

  return (
    <div
      className={"flex flex-wrap gap-10 justify-center over items-stretch"}
      id={"why-us"}
    >
      {data.map((child) => (
        <div
          key={child.title}
          className={
            "w-[290px] md:w-[580px] bg-white-gradient p-[2px] rounded-xl  hover:-translate-y-6 ease-in-out duration-500"
          }
        >
          <div
            className={
              "bg-dark-blue-gradient rounded-xl h-full py-8 px-6 flex md:flex-row flex-col items-start md:space-x-6 md:space-y-0 space-y-4 items-center "
            }
          >
            <img src={child.icon} alt="" className={"w-12 "} />
            <div>
              <h2 className={`text-h3`}>{child.title}</h2>
              <div className={"text-m2 mt-6 font-light text-gray-30 "}>
                {child.description}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
