export default function Navigation() {
  return (
    <div
      className={"flex space-x-16 md:w-[1440px] mb-[34px] pt-10 items-center"}
    >
      <img
        src="/logo.png"
        alt="gt3"
        className={"h-[64px]"}
      />
      <div className={"space-x-10 ml-[61px] max-md:hidden text-grey"}>
        <a href="/#development" className="text-gray-30 hover:text-white">
          Development
        </a>
        <a href="/#why-us" className="text-gray-30 hover:text-white">
          Why chose us?
        </a>
        <a href="/#partners" className="text-gray-30 hover:text-white">
          Partnerships
        </a>
      </div>
    </div>
  );
}
