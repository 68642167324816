import Button from "./Button";

export default function Footer() {
  return (
    <div
      className={
        "flex md:flex-row flex-col space-x-0 md:space-x-12 space-y-8 md:space-y-0 justify-between md:items-center py-[80px]"
      }
    >
      <div className={"shrink-0"}>
        <img src="/logo.png" alt="logo" className="h-20" />
      </div>
      <div className={"flex flex-col items-start md:items-center space-y-4 "}>
        <div className={""}>
          <Button text={"contact us"} />
        </div>
        <div
          className={"max-w-[600px] text-navigation font-light text-gray-30"}
        >
          IT and social media agency, specialized in blockchain development and
          marketing. We operate in a variety of fields, primarily fintech,
          crypto and forex; wherever our clients need to scale their business to
          the next level.
        </div>
      </div>
    </div>
  );
}
