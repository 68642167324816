export default function Partners() {
  const logos = [
    "/partners/Frame 163799.svg",
    "/partners/Frame 163800.svg",
    "/partners/Frame 163801.svg",
    "/partners/Frame 163802.svg",
    "/partners/Frame 163803.svg",
    "/partners/Frame 163804.svg",
    "/partners/Frame 163805.svg",
    "/partners/Frame 163807.svg",
    "/partners/Frame 163808.svg",
    "/partners/Frame 163809.svg",
    "/partners/Frame 163810.svg",
    "/partners/Frame 163811.svg",
    "/partners/Frame 163812.svg",
    "/partners/Frame 163813.svg",
    "/partners/Frame 163814.svg",
    "/partners/Frame 163815.svg",
    "/partners/Frame 163816.svg",
    "/partners/Frame 163817.svg",
    "/partners/Frame 163821.svg",
    "/partners/Frame 163822.svg",
    "/partners/Frame 163823.svg",
    "/partners/Frame 163824.svg",
    "/partners/Frame 163825.svg",
    "/partners/Frame 163826.svg",
  ];

  return (
    <div className={"mx-auto py-[120px]"} id={"partners"}>
      <div className="flex flex-col justify-center">
        <h1 className={"text-h2-mobile md:text-h2 text-center mx-auto"}>
          Partnerships and Integrations
        </h1>
        <p
          className={
            "text-m1-mobile md:text-m1 text-center  w-full max-w-[700px] mx-auto font-light mt-9 text-gray-30"
          }
        >
          Saige is like a cheat code for the market. Its win rates are already
          through the roof, but the real magic of AI is that it keeps improving
          with each new datapoint.
        </p>
        <div
          className={
            "flex flex-wrap justify-center mx-auto gap-10 mt-20 max-w-[900px] w-full"
          }
        >
          {logos.map((logo) => (
            <img
              src={logo}
              alt={logo}
              className={"w-[80px] md:w-[100px] hover:scale-110"}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
