import Button from "./Button";

export default function Companies() {
  return (
    <>
      <div className="flex flex-col md:flex-row md:space-x-12 items-center space-y-12 md:space-y-0 space-y-reverse">
        <div className="grow w-full md:order-0 order-1">
          <img
            src="/companies.png"
            alt="companies"
            className={"md:min-w-[400px] "}
          />
        </div>

        <div
          className={
            "flex flex-col space-y-6 items-start grow md:order-1 order-0"
          }
        >
          <img src="/newscrypto.svg" alt="newscrypto" />
          <div className={"text-m1 text-gray-30"}>
            Started at 2.8 million EUR valuation, reached ATH at 300 million EUR
            marketcap. The price of the token went from 0.02€ to the ATH of 2€
            in 1 year.
          </div>
          <Button
            text={"Visit Site"}
            url={"https://newscrypto.io"}
            target={"_blank"}
            extra={"!w-[157px] !h-[53px]"}
            extra2={"!w-[153px] !h-[49px]"}
          />
        </div>
      </div>
    </>
  );
}
