import Button from "./Button";

export default function Hero() {
  return (
    <div
      className={
        "flex flex-col mt-[80px] md:mt-[160px] relative pb-20 md:pb-40"
      }
    >
      <div className={"relative"}>
        <h1 className={"text-h1-mobile md:text-h1  w-full max-w-[568px] mb-6"}>
          Bring your project millions
        </h1>
        <p
          className={
            "text-m1 w-full  max-w-[400px] md:max-w-[580px] font-light mb-[36px] text-gray-30"
          }
        >
          IT and social media agency, specialized in blockchain development and
          marketing. We operate in a variety of fields, primarily fintech,
          crypto and forex; wherever our clients need to scale their business to
          the next level.
        </p>
        <div className={"w-[185px]"}>
          <Button text={"contact us"} />
        </div>
      </div>
    </div>
  );
}
